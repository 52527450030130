/**
django-markdownx styles for Django Admin
**/

.markdownx .markdownx-editor,
.markdownx .markdownx-preview {
    margin-left: 0;
    box-sizing: border-box;
}
.markdownx .markdownx-preview {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    font-size: 100%;
    font-size: 1em;
    line-height: 1.5em;
    
}
.markdownx .markdownx-preview * {
    line-height: 1.5;
}


/* Django admin overrides */
/* 
.markdownx .markdownx-preview a { color: #5b80b2; text-decoration:underline; }
.markdownx .markdownx-preview a:visited { color: #0b0080; }
.markdownx .markdownx-preview a:hover { color: #0645ad; }
.markdownx .markdownx-preview a:active { color:#faa700; }
.markdownx .markdownx-preview a:focus { outline: thin dotted; }
.markdownx .markdownx-preview a:hover, .markdownx .markdownx-preview a:active { outline: 0; }
.markdownx .markdownx-preview p {
    margin: 1em 0;
    padding: 0;
    font-size: 17px;
}
.markdownx .markdownx-preview img { max-width:100%; }
.markdownx .markdownx-preview h1,
.markdownx .markdownx-preview h2,
.markdownx .markdownx-preview h3,
.markdownx .markdownx-preview h4,
.markdownx .markdownx-preview h5,
.markdownx .markdownx-preview h6 {
    font-weight: normal;
    color: #111;
    margin-top: 0.75em;
    margin-bottom: 0.75em;
    padding: 0;
    background: none;
    font-weight: bold;
}
.markdownx .markdownx-preview h4,
.markdownx .markdownx-preview h5,
.markdownx .markdownx-preview h6 { font-weight: bold; }
.markdownx .markdownx-preview h1 { font-size: 2.5em; }
.markdownx .markdownx-preview h2 { font-size: 2em; }
.markdownx .markdownx-preview h3 { font-size: 1.5em; }
.markdownx .markdownx-preview h4 { font-size: 1.2em; }
.markdownx .markdownx-preview h5 { font-size: 1em; }
.markdownx .markdownx-preview h6 { font-size: 0.9em; }

.markdownx .markdownx-preview blockquote {
    color: #666666;
    margin: 0;
    padding-left: 1.5em;
    border-left: 0.5em #eee solid;
}
.markdownx .markdownx-preview hr {
    display: block;
    height: 0;
    border: 0;
    font-style: italic;
    border-bottom: 1px solid #ccc;
    margin: 20px 0;
    padding: 0;
}
.markdownx .markdownx-preview pre,
.markdownx .markdownx-preview code,
.markdownx .markdownx-preview kbd,
.markdownx .markdownx-preview samp {
    font-family: monospace, monospace;
    font-size: 17px;
}
.markdownx .markdownx-preview code,
.markdownx .markdownx-preview pre  {
    margin: 0 2px;
    padding: 0 5px;
    border: 1px solid #ddd;
    background-color: #f8f8f8;
    border-radius: 2px;
    color: #444;
}
.markdownx .markdownx-preview pre {
    margin: 1.5em 0 1.5em 0;
    padding: 1em;
    white-space: pre;
    word-wrap: break-word;
}
.markdownx .markdownx-preview pre code {
    margin: 0;
    padding: 0;
    background: transparent;
    border: none;
}
.markdownx .markdownx-preview b, .markdownx .markdownx-preview strong  { font-weight: bold; }
.markdownx .markdownx-preview dfn { font-style: italic; }
.markdownx .markdownx-preview ins { background: #ff9; color: #000; text-decoration: none; }
.markdownx .markdownx-preview mark { background: #ff0; color: #000; font-style: italic; font-weight: bold; }
.markdownx .markdownx-preview sub, .markdownx .markdownx-preview sup { font-size: 75%; line-height: 0; position: relative; vertical-align: baseline; }
.markdownx .markdownx-preview sup { top: -0.5em; }
.markdownx .markdownx-preview sub { bottom: -0.25em; }
.markdownx .markdownx-preview ul,
.markdownx .markdownx-preview ol {
    margin: 1em 0 !important; padding: 0 0 0 2em !important;
}
.markdownx .markdownx-preview ul li,
.markdownx .markdownx-preview ol li {
    font-size: 17px !important;
    margin-bottom: 0.75em;
}
.markdownx .markdownx-preview li p:last-child { margin:0 }
.markdownx .markdownx-preview dd { margin: 0 0 0 2em; }
.markdownx .markdownx-preview img  { border: 0; -ms-interpolation-mode: bicubic; vertical-align: middle; }
.markdownx .markdownx-preview table  { border-collapse: collapse; border-spacing: 0; }
.markdownx .markdownx-preview th  { background: none; background: #f8f8f8; font-size: 17px; }
.markdownx .markdownx-preview td  { vertical-align: top; font-size: 17px; } */
