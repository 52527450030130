.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.jumbotron {
  background-image: url("../public/header.png");
  background-size: cover;
  background-position: center 60%;
  padding:3rem 1rem 3rem 1rem; 
  margin:3rem 0rem 1.5rem 0rem;
}

.no-link-style {
  color: inherit; /* テキストの色を継承 */
  text-decoration: none; /* 下線を削除 */
}

.no-link-style:hover,
.no-link-style:focus {
  color: inherit; /* ホバー時の色を継承 */
  text-decoration: none; /* ホバー時の下線を削除 */
}

.bg-custom-1 {
  background-color: #ccf3ff !important;
}

.bg-custom-2 {
  background-color: #f3f3f3 !important;
}

.scrollable-div {
  max-height: 9999px; /* 任意の高さを指定 */
  overflow-y: auto;
  overflow-x: hidden;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding:0;
}

.custom-mark {
  background-color: lightblue; /* 任意の色に変更 */
}

a {
  text-decoration: none; /* 下線を削除 */
}

.toast{
  --bs-toast-font-size: 1rem;
}

.custom-link-style {
  color: inherit; /* テキストの色を継承 */
  text-decoration: none; /* 下線を削除 */
}

.custom-link-style:hover,
.custom-link-style:focus {
  color: inherit; /* ホバー時の色を継承 */
  text-decoration: none; /* ホバー時の下線を削除 */
  opacity:0.6;
	transition:0.1s;
}

.anchor {
  display: block;
  padding-top: 60px !important;
  margin-top: -30px !important;
  }

.stick{
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 60px; /* ナビゲーションバーの高さに合わせて調整 */
}

.image-container img {
  width: 100%;
  height: 150px;
  object-fit: cover; /* 画像をコンテナに合わせる */
  object-position: center; /* 画像を中央に配置 */
}

.right-sidebar-toc{
  font-size: 15px;
}
.right-sidebar-toc li {
  list-style-type: none;
}

.right-sidebar-toc ul{
  padding-inline-start:1em;
}

.body-toc li {
  list-style-type: none;
}

.body-toc ul{
  padding-inline-start:1em;
}

.col-fix{
  width: 8.5%;
  min-width: 40px;
  padding-right: 0px;
  padding-left:0px;
}

.container-body {
  margin-top: 70px;
}
/* 
@media (min-width: 768px) {
  .container-body {
    margin-top: 3px;
  }
} */

.max-width-400 {
  max-width: 400px;
}

.no-wrap-button {
  white-space: nowrap;
}

.body-toc {
  overflow-x: hidden;
}

.toc a {
  display: block;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

.markdownx-preview {
  overflow-x: auto; /* 水平方向にスクロールを許可 */
  word-wrap: break-word; /* 単語の途中でも改行 */
  overflow-wrap: break-word; /* 長い単語を折り返し */
  white-space: normal; /* テキストを折り返し */
  max-width: 100%; /* 親要素からはみ出さない */
}